:global(html) {
  margin: 0;
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:disabled {
  background-color: black;
}


select {
  height: 50px;
  border: 1px solid grey;
  background-color: white;
}

@media (min-width: 768px) and (max-width: 1024px) {
  
  input {
    padding: 0 0 0 11px !important;
  }
  
}
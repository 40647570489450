.table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 15px;
  
  tbody {
    border-bottom: 1px solid #fff;
    font-size: 14px;
  }
}

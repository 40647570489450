.triangle {
  width: 0;
  height: 0;
  border-width: 20px !important;
}
// }
// .up {
//   width: 0;
//   height: 0;
//   border-left: 5px solid transparent;
//   border-right: 5px solid transparent;

//   border-bottom: 5px solid black;
// }

// .down {
//   width: 0;
//   height: 0;
//   border-left: 20px solid transparent;
//   border-right: 20px solid transparent;

//   border-top: 20px solid #f00;
// }

// .right {
//   width: 0;
//   height: 0;
//   border-top: 60px solid transparent;
//   border-bottom: 60px solid transparent;

//   border-left: 60px solid green;
// }

// .left {
//   width: 0;
//   height: 0;
//   border-top: 10px solid transparent;
//   border-bottom: 10px solid transparent;

//   border-right: 10px solid blue;
// }

.carousel {
  max-height: 500px;
  position: relative;
}

.sold {
  display: block;
  width: 80%;
  height: 100%;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.fullWidth {
  height: 60%;
}

.lotNumber {
  position: absolute;
  left: 20px;
  top: 20px;
  @media only screen and (min-width: 530px) {
    top: 5%;
  }
  @media only screen and (min-width: 730px) {
    left: 15%;
    top: 5%;
  }
  @media only screen and (min-width: 1000px) {
    left: 20px;
    top: 10%;
  }
  @media only screen and (min-width: 1450px) {
    left: 10%;
    top: 5%;
  }
  @media only screen and (min-width: 1700px) {
    left: 20%;
  }
}

.lotNumberSlider {
  left: 10%;
  top: 5%;
}

.carouselContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
  display: 'none';
}

.image.active {
  opacity: 1;
}

.image.loaded {
  display: 'block';
}

.buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.buttons:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  opacity: 0.8;
}

.next {
  right: 20px;
}

.previous {
  left: 20px;
}

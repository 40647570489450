.containerLot {
  width: 98%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.lotNumber {
  white-space: nowrap;
}

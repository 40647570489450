.iconLiveBG {
    width: 60%;
    color: #fff;
    background: #2a4365;
    border-bottom-left-radius: 10px;
    position: relative;
    background: #0096cc;
    background: radial-gradient(circle at 0 center, #0096cc 0%, #2a4365 100%);
}

.bold {
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
    font-weight: normal;
}

.buttonAnchorSaleHours {
    background-color: #95cb1d;
    padding: 1.5rem;
    border-radius: 2px;
}

.buttonAnchorCatalog {
    background-color: #2a4365;
    padding: 1.5rem;
    border-radius: 2px;
}
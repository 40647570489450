.uppercase {
  text-transform: uppercase;
}

.price {
  background: #e55221;
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
}

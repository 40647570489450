.width {
  width: 100%;
}

.direction {
  columns: 2;
}

.list {
  padding: 0;
  li {
    list-style: '- ';
    text-transform: capitalize;
  }
}
